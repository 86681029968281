var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.enabled)?_c('v-btn',{attrs:{"color":"primary","id":"btn-show"},domProps:{"textContent":_vm._s(_vm.$t('exportUrl'))},on:{"click":_vm.onShow}}):_vm._e()]}}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_c('span',[_vm._v(_vm._s(_vm.$t('exportUrl')))])]),_c('v-card-text',[_c('div',{attrs:{"id":"preferences"}},[_c('v-checkbox',{attrs:{"label":_vm.$t('exportUrlIncludeSubFolders'),"dense":"","disabled":!_vm.canOperate},model:{value:(_vm.recursive),callback:function ($$v) {_vm.recursive=$$v},expression:"recursive"}}),_c('v-checkbox',{attrs:{"label":_vm.$t('exportUrlIncludeFileName'),"dense":"","disabled":!_vm.canOperate},model:{value:(_vm.includeFileName),callback:function ($$v) {_vm.includeFileName=$$v},expression:"includeFileName"}})],1),(_vm.status === 1)?[_c('v-alert',{attrs:{"dense":"","type":"info"}},[_vm._v("\n                    "+_vm._s(_vm.$t('exportUrlFetchingFiles', {
                            remainingFolderCount: _vm.fetchRemainingFolderCount
                        }))+"\n                ")])]:_vm._e(),(_vm.status === 3)?[_c('v-alert',{attrs:{"dense":"","type":"error"}},[_vm._v("\n                    "+_vm._s(_vm.$t('exportUrlFetchFileFailed', { failReason: _vm.failReason }))+"\n                ")])]:_vm._e(),(_vm.status === 2)?[_c('v-textarea',{attrs:{"readonly":"","solo":"","auto-grow":"","id":"downloadUrls","value":_vm.downloadUrls}}),_c('v-alert',{attrs:{"dense":"","type":"info"}},[_vm._v("\n                    "+_vm._s(_vm.$t('exportUrlStats', {
                            fileCount: _vm.files.length,
                            folderCount: _vm.folders.length,
                            totalDownloadSize: _vm.totalDownloadSize
                        }))+"\n                ")])]:_vm._e()],2),_c('v-card-actions',{attrs:{"id":"actions"}},[_c('v-btn',{attrs:{"text":""},domProps:{"textContent":_vm._s(_vm.$t('close'))},on:{"click":_vm.close}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }