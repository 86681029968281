var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":true,"max-width":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.enabled)?_c('v-btn',{attrs:{"color":"primary","id":"btn-show"},domProps:{"textContent":_vm._s(_vm.$t('aria2Download'))},on:{"click":_vm.onShow}}):_vm._e()]}}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_c('span',[_vm._v(_vm._s(_vm.$t('aria2Download')))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-text-field',{attrs:{"label":_vm.$t('aria2RPCDownloadPath')},model:{value:(_vm.downloadPath),callback:function ($$v) {_vm.downloadPath=$$v},expression:"downloadPath"}})],1),_c('v-row',[_c('v-checkbox',{attrs:{"label":_vm.$t('aria2DownloadRecursive'),"disabled":!_vm.canOperate},model:{value:(_vm.recursive),callback:function ($$v) {_vm.recursive=$$v},expression:"recursive"}})],1)],1),(_vm.status === 1)?[_c('v-alert',{attrs:{"dense":"","type":"info"}},[_vm._v("\n                    "+_vm._s(_vm.$t('aria2FetchingFiles', {
                            remainingFolderCount: _vm.fetchRemainingFolderCount
                        }))+"\n                ")])]:_vm._e(),(_vm.status === 2)?[_c('v-alert',{attrs:{"dense":"","type":"info"}},[_vm._v("\n                    "+_vm._s(_vm.$t('aria2DownloadStats', {
                            fileCount: _vm.files.length,
                            folderCount: _vm.folders.length,
                            totalDownloadSize: _vm.totalDownloadSize
                        }))+"\n                ")])]:_vm._e(),(_vm.status === 3)?[_c('v-alert',{attrs:{"dense":"","type":"info"}},[_vm._v("\n                    "+_vm._s(_vm.$t('aria2DownloadProgress', {
                            remainingFileCount: _vm.remainingFileCount
                        }))+"\n                ")])]:_vm._e(),(_vm.status === 4)?[_c('v-alert',{attrs:{"dense":"","type":"success"}},[_vm._v("\n                    "+_vm._s(_vm.$t('aria2DownloadSuccess'))+"\n                ")])]:_vm._e(),(_vm.status === 5)?[_c('v-alert',{attrs:{"dense":"","type":"error"}},[_vm._v("\n                    "+_vm._s(_vm.$t('aria2DownloadFailed', { failReason: _vm.failReason }))+"\n                ")])]:_vm._e(),(_vm.status === 6)?[_c('v-alert',{attrs:{"dense":"","type":"error"}},[_vm._v("\n                    "+_vm._s(_vm.$t('aria2FetchFileFailed', { failReason: _vm.failReason }))+"\n                ")])]:_vm._e(),(_vm.shouldShowAriaHTTPSWarning)?_c('v-alert',{attrs:{"dense":"","type":"warning"}},[_vm._v("\n                "+_vm._s(_vm.$t('aria2HTTPSWarning'))+"\n            ")]):_vm._e()],2),_c('v-card-actions',{attrs:{"id":"actions"}},[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.canOperate,"text":""},domProps:{"textContent":_vm._s(_vm.$t('start'))},on:{"click":_vm.startDownload}}),_c('v-btn',{attrs:{"text":""},domProps:{"textContent":_vm._s(_vm.$t('close'))},on:{"click":_vm.close}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }